<template>
	<div class="alert" v-if="showEnds && route.path !== '/login'">
		<span v-if="!isExpired">{{ $t('sonuser.ka_global', { time: diffTime }) }}</span>
		<span v-else>{{ $t('sonuser.ka_expired_global') }}</span>
	</div>
	<NConfigProvider
		:theme-overrides="themeOverrides"
		style="height: 100%"
		:locale="naiveI18nLocale"
		:date-locale="dateZhCN"
	>
		<el-config-provider :locale="i18nLocale">
			<n-dialog-provider>
				<router-view v-show="getThemeConfig.lockScreenTime !== 0" />
			</n-dialog-provider>
		</el-config-provider>
	</NConfigProvider>
</template>

<script lang="ts" setup>
import { computed, ref, getCurrentInstance, onMounted, onUnmounted, nextTick, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '/@/store/index'
import { useTitle, SesstionTitle } from '/@/utils/setWebTitle'
import { Local } from '/@/utils/storage'
import { setWebicon } from './utils/setWebIcon'
import { setThem } from './utils/theme'
import {
	GlobalThemeOverrides,
	NConfigProvider,
	NDialogProvider,
	zhCN,
	dateZhCN,
	dateEnUS,
	enUS,
} from 'naive-ui'
import { setUtm } from './utils/config'
import uploadUser from '@/utils/uploadUserInfo'
import moment from 'moment'
import { i18n } from './i18n'
import { resetStateAndLogout } from './utils'
import { NextLoading } from '/@/utils/loading'

const { proxy } = getCurrentInstance() as any
const { t } = i18n.global
const setingsRef = ref()
const route = useRoute()
const store = useStore()
const title = useTitle()
// 获取布局配置信息
const getThemeConfig = computed(() => {
	return store.state.themeConfig.themeConfig
})

const openSetingsDrawer = () => {
	setingsRef.value.openDrawer()
}
const i18nLocale = ref<any>()
const naiveI18nLocale = ref<any>()
const dateLocal = ref(dateZhCN)
// 页面加载时
onMounted(async () => {
	if (Object.keys(route.meta).length == 0) {
		//页面刷新时加载
		SesstionTitle()
	}
	NextLoading.start()
	await uploadUser() //更新用户信息
	NextLoading.done()
	setUtm() //设置utm信息
	nextTick(() => {
		// 监听布局配置弹窗点击打开
		proxy.mittBus.on('openSetingsDrawer', () => {
			openSetingsDrawer()
		})
		Local.set('themeConfig', getThemeConfig.value) //设置全局主题
		// 获取缓存中的布局配置
		if (Local.get('themeConfig')) {
			store.dispatch('themeConfig/setThemeConfig', Local.get('themeConfig'))
			document.documentElement.style.cssText = Local.get('themeConfigStyle')
			store.dispatch('platForm/setPlatInfos')
			//设置全局主题
			setThem()
			//设置网站icon
			setWebicon(store.state.platForm.favicon)
		}

		proxy.mittBus.on('getI18nConfig', (locale: any) => {
			i18nLocale.value = locale
			if (locale.name !== 'en') {
				dateLocal.value = dateZhCN
				naiveI18nLocale.value = zhCN
			} else {
				naiveI18nLocale.value = enUS
				dateLocal.value = dateEnUS
			}
		})

		proxy.mittBus.emit('getI18nConfig', proxy.$i18n.messages[getThemeConfig.value.globalI18n])
	})
})
// 页面销毁时，关闭监听布局配置
onUnmounted(() => {
	proxy.mittBus.off('openSetingsDrawer', () => {})
})

// 监听路由的变化，设置网站标题
watch(
	() => route.path,
	() => {
		title()
	}
)

const themeOverrides = computed<GlobalThemeOverrides>(() => ({
	common: {
		primaryColor: store.state.platForm.theme_color,
		primaryColorHover: store.state.platForm.theme_color,
		primaryColorPressed: store.state.platForm.theme_color,
		heightMedium: '32px',
		borderRadius: '4px',
	},
	Scrollbar: {
		color: 'rgba(0,0,0,.4)',
		colorHover: 'rgba(0,0,0,.6)',
		common: {
			scrollbarWidth: '7px',
			scrollbarHeight: '7px',
		} as any,
	},
}))

if (!String.prototype.replaceAll) {
	String.prototype.replaceAll = function (s1: any, s2: any) {
		return this.replace(new RegExp(s1, 'gm'), s2)
	}
}

const endsAt = computed(() => store.state.userInfos.ka_ends_at * 1000)
const isExpired = computed(() => (endsAt.value ? endsAt.value < moment().valueOf() : false))

const diffTime = computed(() => {
	const curDate = moment()
	const hours = moment(endsAt.value).diff(moment(curDate), 'hours')
	return hours > 24
		? `${Math.ceil(hours / 24)} ${t('sonuser.day')}`
		: `${hours} ${t('sonuser.hours')}`
})

const showEnds = computed(() => {
	const curDate = moment()
	const userInfo = store.state.userInfos
	const flag = userInfo.is_ka === 1 && userInfo.pid <= 0
	const hours = moment(endsAt.value).diff(moment(curDate), 'hours')
	if (isExpired.value && flag) return true
	if (hours <= 24 * 3 && hours > 0 && flag) return true
	return false
})

if (!Local.get('account-font-platInfo')) resetStateAndLogout()
</script>

<style lang="scss">
.tooltip-text-wrap {
	max-width: 360px;
	line-height: 1.5;
	color: rgba(255, 255, 255, 0.9) !important;
	word-break: break-all;
}

.alert {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	padding: 0 32px;
	text-align: center;
}

.compare {
	display: flex;
	align-items: center;
	justify-content: center;

	&.left {
		justify-content: flex-start;
	}

	i {
		font-size: 18px;
	}

	&.default {
		i {
			display: none;
		}
	}

	&.down {
		color: var(--color-error);

		i {
			transform: rotate(-90deg);
		}
	}

	&.top {
		color: #00abb1;

		i {
			transform: rotate(90deg);
		}
	}
}
</style>
