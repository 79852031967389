// Defined content
export default {
	lang: {
		router: {
			homeInfo: 'Stores',
			home: 'List',
			homeCancel: 'Deactivate',
			billManage: 'Billing',
			sonuserManage: 'Accounts',
			wallet: 'Wallet',
			invoiceManage: 'Invoices',
			domainManage: 'Domains',
			statistics: 'Analytics',
			storeStatistics: 'Stores',
			accountStatistics: 'accounts',
			messages: 'Messages',
			commentFeedback: 'Feedback',
			businessDetail: 'Business detail',
			orders: 'My orders',
			domainPanel: 'Control panel',
			domainReg: 'Domain registration',
			dns: 'Domain name system',
			applyInvoice: 'Apply',
			invoiceDetail: 'View details',
			balanceRecharge: 'Balance recharge',
			motivationSharing: 'Affiliate',
			dashboard: 'Dashboard',
			billRecord: 'Billing',
			withdraw: 'Withdrawals',
			smp: 'SCM',
			dashboardHome: 'Dashboard',
			storesAuth: 'Auth',
			panel: 'Panel',
			dedicatedIp: 'Website dedicated IP',
			dedicatedIpBuy: 'Buy dedicated IP',
		},
		staticRoutes: {
			signIn: 'Sign in',
			notFound: 'Page not found',
			noPower: 'No permission',
			terms: 'Terms',
			underReview: 'Under review',
			payout: 'Quick payment deduction authorization confirmation',
			suspend: 'System error',
		},
		user: {
			home: 'Store home', // Store Home
			personal: 'Personal center', // Personal Center
			help: 'Help center', // Help Center
			logOut: 'Log out', // Log Out
			counselor: 'Site consultant',
			server: 'Online service',
			contact: 'If you have any questions or needs, please contact us. Working hours: 9:00-18:00',
			api: 'API documentation',
			evaluate: 'Feedback',
			title0: 'Component size',
			title1: 'Language switching',
			msg: 'Message center',
			not_msg: 'No message notifications!',
			title2: 'Menu search',
			title3: 'Layout configuration',
			title4: 'Message',
			title5: 'Full screen',
			title6: 'Exit full screen',
			dropdownDefault: 'Default',
			dropdownMedium: 'Medium',
			dropdownSmall: 'Small',
			dropdownMini: 'Mini',
			dropdown3: '404',
			dropdown4: '401',
			dropdown5: 'Log out',
			dropdown6: 'Code repository',
			searchPlaceholder: 'Menu search: supports Chinese, route paths',
			newTitle: 'Notifications',
			newBtn: 'Mark all as read',
			newGo: 'Go to notification center',
			newDesc: 'No notifications',
			logOutTitle: 'Confirmation',
			logOutMessage: 'This operation will log you out. Are you sure?',
			logOutConfirm: 'OK',
			logOutCancel: 'Cancel',
			logOutExit: 'Exiting',
			logOutSuccess: 'Logged out successfully!',
			guide: 'Store opening guide',
		},
		tagsView: {
			refresh: 'Refresh',
			close: 'Close',
			closeOther: 'Close other',
			closeAll: 'Close all',
			fullscreen: 'Full screen',
		},
		notFound: {
			foundTitle: 'Address input error, please enter the address again~',
			foundMsg:
				'You can first check the website address, then re-enter it or give us feedback on the issue.',
			foundBtn: 'Return to homepage',
		},
	},
}
