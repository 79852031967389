import { getUrlParams, openUrl } from '.'
import { getStoreToken, getSubUserToken } from '../api/common'
import { i18n } from '../i18n'
import { Cookies, Local } from './storage'

/**
 * 获取接口域名（只能在组件内使用）
 * @returns 拼接后的域名
 */
export function api(): string {
	return `/account-api/front`
}
//获取当前域名的一级域名
export const getNowDomain = () => {
	let domain, domainParts, host
	host = location.host
	domainParts = host.split('.')
	domainParts.shift()
	domain = domainParts.join('.')
	return domain
}

//设置utm配置
export const setUtm = () => {
	const utm_medium = getUrlParams('utm_medium') //来源
	const utm_source = getUrlParams('utm_source') //来源
	const ref = getUrlParams('ref') //推荐人
	const ref_channel = getUrlParams('ref_channel') //推荐渠道
	const phone = getUrlParams('phone') //电话
	//设置相关信息
	Cookies.set('utm_medium', utm_medium, { expires: 90, domain: getNowDomain() })
	Cookies.set('utm_source', utm_source, { expires: 90, domain: getNowDomain() })
	Cookies.set('ref', ref, { expires: 90, domain: getNowDomain() })
	Cookies.set('ref_channel', ref_channel, { expires: 90, domain: getNowDomain() })
	Cookies.set('phone', phone, { expires: 90, domain: getNowDomain() })
}

//获取当前域名并接token跳转
export const urlToken = (token) => {
	const platInfo = Local.get('account-font-platInfo')
	return `http://${platInfo.front_domain}?token=${token}`
}

//查看子账号
export const checkSounUser = async (id: string | number) => {
	const { data } = await getSubUserToken(id)
	Cookies.remove()
	openUrl(urlToken(data?.token))
}

//查看店铺
export const checkStores = async (id: string | number) => {
	const { data } = await getStoreToken(id)
	openUrl(data?.url as string)
}

//获取当前系统多语言配置
export const getLangConfing = () => {
	return Local.get('account-font-userInfo')?.language || 'zh-cn'
}

export const getGmtConfigs = () => {
	const { t } = i18n.global
	return [
		{ label: t('common.gmt5'), value: 'Etc/GMT+5', tip: t('home.timezone_tip') },
		{ label: t('common.gmt8'), value: 'Etc/GMT-8' },
		{ label: t('common.gmt-12'), value: 'Etc/GMT+12' },
		{ label: t('common.gmt-11'), value: 'Etc/GMT+11' },
		{ label: t('common.gmt-13'), value: 'Etc/GMT+10' },
		{ label: t('common.gmt-9'), value: 'Etc/GMT+9' },
		{ label: t('common.gmt-8'), value: 'Etc/GMT+8' },
		{ label: t('common.gmt-14'), value: 'Etc/GMT+7' },
		{ label: t('common.gmt-15'), value: 'Etc/GMT+6' },
		{ label: t('common.gmt-31'), value: 'Etc/GMT+4' },
		{ label: t('common.gmt-16'), value: 'Etc/GMT+3' },
		{ label: t('common.gmt-17'), value: 'Etc/GMT+2' },
		{ label: t('common.gmt-18'), value: 'Etc/GMT+1' },
		{ label: t('common.gmt-10'), value: 'Etc/UTC' },
		{ label: t('common.gmt-19'), value: 'Etc/GMT-1' },
		{ label: t('common.gmt-20'), value: 'Etc/GMT-2' },
		{ label: t('common.gmt-21'), value: 'Etc/GMT-3' },
		{ label: t('common.gmt-22'), value: 'Etc/GMT-4' },
		{ label: t('common.gmt-23'), value: 'Etc/GMT-5' },
		{ label: t('common.gmt-24'), value: 'Etc/GMT-6' },
		{ label: t('common.gmt-25'), value: 'Etc/GMT-7' },
		{ label: t('common.gmt-26'), value: 'Etc/GMT-9' },
		{ label: t('common.gmt-27'), value: 'Etc/GMT-10' },
		{ label: t('common.gmt-28'), value: 'Etc/GMT-11' },
		{ label: t('common.gmt-29'), value: 'Etc/GMT-12' },
		{ label: t('common.gmt-30'), value: 'Etc/GMT-13' },
	]
}
