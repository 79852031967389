export default {
	dedicatedIp: {
		used: 'How to use',
		title_desc:
			'Advantages: SEO benefits / Avoid shared risks / Privacy / Monthly subscription, replace anytime / Reusable',
		buy_ip: 'Buy ip',
		ip_place: 'Enter ip',
		effected: 'Activated',
		assigning: 'Assigning',
		assigning_tip: 'Assigning ip, please wait',
		expired: 'Expiring soon',
		expired_tip: 'Ip will be automatically deleted after expiration, please renew in time',
		ip_address: 'Ip address',
		unit_price: 'Unit price',
		cancel_sub: 'Cancel subscription',
		recover_sub: 'Restore subscription',
		check_detail: 'View details',
		month: 'Month',
		cancel_sub_title: 'Do you want to cancel auto-renewal?',
		notice: 'Notice:',
		cancel_sub_desc1:
			'1. After cancellation, renewal is valid within 48 hours of IP expiration; otherwise, it will be automatically deleted.',
		cancel_sub_desc2: '2. The ip will be released and become unavailable after expiration',
		cancel_sub_desc3:
			'3. If any domain is resolved to this ip, please unbind it from your store or switch the CDN type',
		recover_sub_title: 'Do you want to restore auto-renewal?',
		recover_sub_desc1:
			'1. After recovery, the IP will be automatically renewed at 1:00 AM (Beijing time) within 5 days before the expiration date.',
		recover_sub_desc2:
			'2. Auto-renewal will occur monthly; please ensure sufficient balance for successful renewal',
		recover_sub_desc3: '3. To cancel the subscription, please do so manually',
		detail_legend: 'Details',
		detail_desc1:
			'1. Log in to the domain service provider’s backend and add the “correct record value” as prompted',
		detail_desc2:
			'2. Go to the store backend → “Store decoration” → “Domain management” → Enter the domain to bind → Select “Dedicated ip”',
		detail_desc3:
			'3. Please wait patiently for the server resolution to complete; the system will activate automatically',
		record_type: 'Record type',
		host_type: 'Host type',
		proper_record_val: 'Correct record value:',
		back_ip: 'Return to website dedicated ip',
		bill_type: 'Billing type',
		bill_type_desc:
			'Discounts are offered based on one-time purchase quantity, with final pricing based on purchase amount',
		num_place: 'Enter purchase quantity',
		refer_price: 'Reference unit price:',
		current_num: 'Current quantity:',
		confirm_order: 'Confirm order',
		total: 'Total:',
		unpaid_order: 'Unpaid order',
		total_price: 'Total amount:',
		order_number: 'Order number:',
		order_info: 'Order information:',
		total_ip: 'Total {num} ip',
		wallet_balance: 'Wallet balance',
		current_use_balance: 'Current available balance',
		alipay: 'Alipay online',
		cope_price: 'Payable amount:',
		confirm_pay: 'Confirm payment',
		buy_info: 'Purchasing... please wait (Do not leave or refresh the page)',
		buy_field1: 'Purchase quantity',
		buy_field2: 'Reference unit price',
		buy_field3: 'Payment type',
		buy_field3_tip: 'You can cancel later in the list after purchase',
		buy_field4: 'Traffic type',
		buy_field5: 'Additional services',
		assigning_success: 'Assignment successful',
		assigning_failed: 'Assignment failed',
		example_pause: 'Instance paused',
		example_pause_tip:
			'Renewal is valid within 48 hours after expiration; otherwise, a deletion task will be executed. Deletion occurs automatically at 2:00 AM Beijing time.',
		sub_status: 'Subscription status',
		auto_renewal: 'Auto-renewal',
		auto_renewal_tip:
			'Automatic renewal at 1:00 AM (Beijing time) within 5 days before the expiration date.',
		not_renewal: 'No renewal',
		handle_status: 'Processing status',
	},
}
